define(['$window', 'app'], ($window, app) => {

  const responsiveSort = () => {
    var component = {};

    const _config = {
      selectors: {
        sortBySelector: '[data-sort-by]',
        productListWrapper: '[data-product-list-wrapper]',
      },
      attribs: {
        sortOrderSelected: 'sort-order-selected',
      },
      keys: {
        sortByKey: 'sortOrder'
      }
    };

    const _init = (element) => {
      component.element = element;

      component.wrapper = document.querySelector(component.config.selectors.productListWrapper);
      component.sortBySelectElem = component.element.querySelector(component.config.selectors.sortBySelector);

      if (component.wrapper.getAttribute(component.config.attribs.sortOrderSelected)) {
        component.sortBySelectElem.focus();
        component.wrapper.removeAttribute(component.config.attribs.sortOrderSelected);
      }

      component.bind();

      return component;
    };

    const _bind = () => {
      if (component.sortBySelectElem) {
        if (!component.sortBySelectElem.value) {
          component.sortBySelectElem.value = 'default';
        }
        component.sortBySelectElem.addEventListener('change', component.sortBySelected);
      }
    };

    const _sortBySelected = (event) => {
      let value = event.target.value;
      if (!value) return;

      component.wrapper.setAttribute(component.config.attribs.sortOrderSelected, 'true');

      component.updatePage(value);
    };

    const _updatePage = (value) => {
      app.publish('responsiveProductListPage/update', { sortOrder: value });

      window.dataLayer.push({
        'event': 'sort_by',
        'method': event.target.value
      });
      
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.sortBySelected = _sortBySelected;
    component.updatePage = _updatePage;

    return component;
  };

  return responsiveSort;
});
